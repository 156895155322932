import React, { useState } from 'react';
import './App.css';
import Modal from 'react-modal'; 
import TypingAnimation from './TypingAnimation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { faHtml5 } from '@fortawesome/free-brands-svg-icons';
import { faJs } from '@fortawesome/free-brands-svg-icons';
import { faPhp } from '@fortawesome/free-brands-svg-icons';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';
import { faPython } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons'

Modal.setAppElement('#root'); // Esto asegura que react-modal se adjunte correctamente al DOM

function App() {
  const [mostrarModal, setMostrarModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [nombreVacio, setNombreVacio] = useState(false);

  const abrirModal = () => {
    setMostrarModal(true);
    setNombre(''); // Esto reiniciará el campo de nombre
    setNombreVacio('');
  };
  

  const cerrarModal = () => {
    setMostrarModal(false);
  };

  const handleNombreChange = (e) => {
    setNombre(e.target.value);
  };

  const enviarMensaje = () => {
    if (nombre.trim() === '') {
      setNombreVacio(true);
    } else {
      const mensaje = `Hola Marcos!, soy ${nombre} y quería charlar contigo...`;
      const linkWhatsApp = `https://api.whatsapp.com/send?phone=3316327914&text=${encodeURIComponent(mensaje)}`;
      window.open(linkWhatsApp, '_blank');
      
      // Cerrar el modal cuando se haya enviado el mensaje
      cerrarModal();
    }
  };
  
  
  return (
    <div className="App">
      <header>
        {/* Contenido del encabezado */}
        <div className="container">
          <div className="sticky-div">
            <div className="nombre">marcos enríquez</div>
            <div className="xsocial">
              <a
                href="https://x.com/_marcosdev"
                target="_blank"
                rel="noopener noreferrer"
                className="twitter-link"
              >
                <FontAwesomeIcon icon={faXTwitter} />
              </a>&nbsp;&nbsp;&nbsp;<a
                href="https://instagram.com/_marcosenriquez"
                target="_blank"
                rel="noopener noreferrer"
                className="twitter-link"
              ><FontAwesomeIcon icon={faInstagram} /></a>
            </div>
          </div>
        </div>
      </header>
      <main>
        {/* Contenido principal */}
        <div className="container">
          <div className="imagen">
            <img src="/marcosdev.jpg" alt="" className="imagen-logo" />
          </div>
          <div className="efecto"><TypingAnimation /></div>
        </div>
        <div className="brands">
        <FontAwesomeIcon icon={faHtml5} size="2xl" />&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faJs} size="2xl" />&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faReact} size="2xl" />&nbsp;&nbsp;&nbsp;
        <FontAwesomeIcon icon={faPhp} size="2xl" />&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faAndroid} size="2xl" />&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faPython} size="2xl" />
        </div>
        <div className="contenedor-info">
      <div className="lado-izquierdo">
        {/* Contenido del lado izquierdo */}
        &nbsp;
      </div>
      <div className="centro">
        {/* Tu introducción */}
        <b>¡Hola! Soy un Ingeniero de Software nacido en la ciudad de Guadalajara, Jalisco, México.</b><p></p>
        Me encanta la innovación y el desarrollo tecnológico, me gusta crear soluciones de software que transformen ideas en realidad.<p>
        </p>Intento diseñar y construir aplicaciones que marquen la diferencia. Mi experiencia en el desarrollo de software me ha llevado a explorar un amplio espectro de tecnologías, desde lenguajes de programación como JavaScript, Python y PHP hasta el pequeño ejemplo de esta web en React, me encanta trabajar con <font color="#3679FF "><b>Dart</b></font> y <font color="#3679FF "><b>Flutter</b></font>.<p>
        </p> Mi pasión por el aprendizaje constante me impulsa a mantenerme al día con las últimas tendencias tecnológicas. En mi tiempo libre, colaboro en proyectos de código abierto y proyectos propios dentro de <font color="#FC744F "><b>creamoscodigo.com</b></font><p></p>

      <div className="contenedor-sobre-mi">
      <div className="columna-sobremi-izq">
        {/* Contenido del primer div */}
        <FontAwesomeIcon icon={faEnvelope} size="lg" />&nbsp;&nbsp;marcos@creamoscodigo.com
      </div>
      <div className="columna-sobremi-centro">
        {/* Contenido del segundo div */}
        <FontAwesomeIcon icon={faCode} size="lg" />
      </div>
      <div className="columna-sobremi-der">
        {/* Contenido del tercer div */}
        <FontAwesomeIcon icon={faWhatsapp} size="lg" />&nbsp;&nbsp;<button onClick={abrirModal} class="boton-pequeno">¿Charlamos?</button>
      </div>
      </div>


      </div>
      <div className="lado-derecho">
        {/* Contenido del lado derecho */}
        &nbsp;
      </div>
    </div>
    {/* Modal */}
    {mostrarModal && (
        <div className="modal-fondo">
          <div className="modal-contenedor">
            <div className="modal-cerrar" onClick={cerrarModal}>
              &#x2715; {/* Código Unicode para una "x" */}
            </div>
            <h2>¿Me regalas tu nombre?</h2>
            {/* Contenido del modal */}
            <input
            type="text"
            value={nombre}
            onChange={handleNombreChange}
            placeholder=""
            required
            title="Por favor, ingresa tu nombre"
          />
            &nbsp;

            <button className='enviarnombre' onClick={enviarMensaje}>
              ¡Claro!
            </button>
            {nombreVacio && (
            <p className="error-mensaje">Ups! necesito tu nombre 😢.</p>
          )}
          </div>
        </div>
        )}
      </main>
      <footer className="footer">
        {/* Contenido de tu footer */}
        <div className='fotter-amor'>
        hecho con el <FontAwesomeIcon icon={faHeart} /> y un poco de <FontAwesomeIcon icon={faReact} />&nbsp;en Guadalajara, Mx.&nbsp;V.1.0
        </div>
      </footer>
    </div>
  );
}

export default App;

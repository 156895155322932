import React, { useState, useEffect } from 'react';

const TypingAnimation = () => {
  const frases = ["Soy Marcos Enríquez", "Ing. Desarrollo de Software", "Android & Web & .exe"];
  const [fraseIndex, setFraseIndex] = useState(0);
  const [mensaje, setMensaje] = useState("");
  const [cursorVisible, setCursorVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (cursorVisible) {
        setMensaje((mensaje) => mensaje + frases[fraseIndex].charAt(mensaje.length));
      }

      setCursorVisible(!cursorVisible);

      if (mensaje === frases[fraseIndex]) {
        setTimeout(() => {
          setFraseIndex((fraseIndex + 1) % frases.length);
          setMensaje("");
        }, 1000); // Cambiar de frase después de 1 segundo
      }
    }, 100); // Cambiar caracteres cada 0.1 segundos

    return () => clearInterval(interval);
  }, [fraseIndex, mensaje, cursorVisible]);

  return (
    <div className="typing-animation">
      {mensaje}
      {cursorVisible}
    </div>
  );
}

export default TypingAnimation;
